.container {
  background-color: black;
  width: "100%";
  max-width: 100vw;
  color: #e0fbfc;
  min-height: 100vh;
}

.transitions-item {
  /* overflow: hidden; */
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
  will-change: transform, opacity, height;
  /* white-space: nowrap; */
  cursor: pointer;
  line-height: 20px;
}